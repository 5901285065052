body.create-flow-open {
	overflow: hidden;
}

.cfc--main-title {
	span {
		display: block;
		@include carbon--type-style("productive-heading-02");
	}
}
.sc--createflow {
	background: #ffffff;
	display: flex;
	position: fixed;
	width: 100%;
	z-index: 2;
	padding-top: 3rem;
	bottom: 0;
	top: 3rem;
	justify-content: flex-start;
	flex-direction: column;
	overflow-y: scroll;
	left: 0;
	right: 0;
	&-intro {
		align-items: center;
	}
	&-button-wrapper {
		button {
			min-width: 200px;
			&:first-of-type {
				margin-right: 2rem;
			}
		}
	}

	&-intro {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: $layout-04;
	}
	&-progress-indicator-wrapper {
		width: 100%;
		margin-bottom: $layout-03;
	}

	&-step {
		display: flex;
		justify-content: space-between;
		margin-left: -2rem;
		margin-right: -2rem;
	}
	&-fileupload {
		justify-content: space-between;
		flex-direction: row;
		display: flex;
		&.csv-upload {
			flex-direction: column;
		}
		margin-top: 2rem;
		label {
			width: 200px;
			height: 48px;
			margin: 1rem 0;
		}
		.bx--file__selected-file {
			min-width: 200px;
			height: 48px;
		}
		.bx--file__state-container .bx--file-close {
			fill: $gray-70;
		}
	}
}

.sc--body {
	@include carbon--type-style(body-short-01, true);
	&.description {
		margin-top: $spacing-05;
	}
}

.sc--createflow-column {
	padding-left: 2rem;
	padding-right: 2rem;
	flex: 0 1 auto;
	width: 100%;
	margin-bottom: 2rem;
	> div:not(.sc--details-title) {
		margin-bottom: $spacing-06;
	}
	.title {
		margin-bottom: $spacing-06;
		@include carbon--type-style(productive-heading-03, true);
	}

	// .bx--form-item {
	// 	margin-top: $spacing-05;
	// }
	.sc--details-postcode-state-wrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		#customer-add--address-state {
			margin-right: $spacing-07;
			// margin-top: $spacing-05;
		}
		.bx--list-box__wrapper {
			flex: 1 1 auto;
			margin-right: 2rem;
			// margin-top: 1rem;
		}
		.bx--form-item {
			flex: 0 1 25%;
		}
	}
	.bx--file-container {
		margin-top: 1rem;
	}
}

.sc--details-country-wrapper {
	.bx--dropdown__wrapper {
		width: 100%;
	}
}

.sc--createflow-progress-indicator {
	.bx--progress-step--current {
		.bx--progress-label {
			font-weight: bold;
		}
	}
	.bx--progress-step--disabled {
		pointer-events: none;
	}
}

.bx--progress-step-button {
	position: relative;
	span {
		svg {
			position: absolute;
			fill: $red-60 !important;
			margin: 0 0 0 5px;
		}
	}
}
