.sc--login-page,
.sc--sign-out-page {
	.sc--login-form-wrapper,
	.sc--sign-out-form-wrapper {
		height: calc(100vh - 6rem - 48px);
		align-items: center;
	}

	.bx--text-input-wrapper {
		margin-bottom: 1rem;
	}

	.bx--form__requirements {
		color: $support-01;
		line-height: 1.4;
	}

	.sc--success-message {
		color: $support-02;
		@include carbon--type-style(body-short-01, true);
	}

	.login-intro p {
		margin-bottom: $spacing-07;
		&:first-of-type {
			@include carbon--type-style(productive-heading-03, true);
		}
	}

	.sc--sign-out-main {
		p {
			@include carbon--type-style("display-02");
			span {
				margin-top: $spacing-05;
				display: block;
				margin-bottom: $spacing-07;
				@include carbon--type-style("expressive-heading-02");
			}
		}
	}

	.sc--sign-out-image-wrapper {
		text-align: center;
		img {
			width: 100%;
			height: auto;
			max-width: 35rem;
		}
	}
	.login-link {
		margin-top: $spacing-06;
		display: inline-block;
	}
}
