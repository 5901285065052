$requestFlowPrefix: "sc--request-flow";
$requestTilePrefix: "sc--request-tile";
$tileRequest: "tile-request";

body.create-flow-open {
	overflow: hidden;
}

.#{$requestFlowPrefix} {
	background: #ffffff;
	display: flex;
	position: fixed;
	width: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 2;
	padding-top: 3rem;
	bottom: 0;
	top: 3rem;
	justify-content: flex-start;
	flex-direction: column;
	overflow-y: scroll;
	&-intro {
		align-items: center;
	}
	&-button-wrapper {
		button {
			min-width: 200px;
			&:first-of-type {
				margin-right: 2rem;
			}
		}
	}

	&-intro {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: $layout-04;
	}
	&-progress-indicator-wrapper {
		width: 100%;
		margin-bottom: $layout-03;
	}

	&-step {
		display: flex;
		justify-content: space-between;
		margin-left: -2rem;
		margin-right: -2rem;
	}
	&-fileupload {
		justify-content: space-between;
		flex-direction: row;
		display: flex;
		&.csv-upload {
			flex-direction: column;
		}
		margin-top: 2rem;
		label {
			width: 200px;
			height: 48px;
			margin: 1rem 0;
		}
		.bx--file__selected-file {
			min-width: 200px;
			height: 48px;
		}
		.bx--file__state-container .bx--file-close {
			fill: $gray-70;
		}
	}
}

.#{$requestFlowPrefix}-content-switcher {
	max-width: 500px;
	margin-bottom: $spacing-05;
}

.#{$requestTilePrefix} {
	display: flex;
	margin-bottom: $spacing-07;
	a {
		min-height: 250px;
		width: 100%;
		border-radius: 10px;
		color: $carbon--gray-70 !important;
		text-decoration: none !important;
		border: 1px solid #dadada;
	}
}

.#{$requestTilePrefix} {
	.tile-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 250px;
		padding: $spacing-03;
	}
	strong {
		font-weight: 900;
		display: block;
	}

	.#{$tileRequest} {
		&-title {
			@include carbon--type-style("expressive-heading-03");
		}
		&-date {
			@include carbon--type-style("body-long-01");
			padding-top: $spacing-03;
		}
	}
}

.tile-request-description-wrapper {
	border-top: 1px solid #dadada;
	padding-top: $spacing-05;
	margin-top: $spacing-05;
	> div {
		padding-bottom: $spacing-03;
	}
	.bx--tag {
		font-size: 12px;
		border-radius: 0;
	}
}

.#{$tileRequest}-icon {
	vertical-align: text-top;
	margin-right: 0.25rem;
}

.sc--body {
	@include carbon--type-style(body-short-01, true);
	&.description {
		margin-top: $spacing-05;
	}
}

.#{$requestFlowPrefix}-column {
	padding-left: 2rem;
	padding-right: 2rem;
	flex: 0 1 50%;
	width: 100%;
	margin-bottom: 2rem;
	> div:not(.sc--details-title) {
		margin-bottom: $spacing-06;
	}
	.title {
		margin-bottom: $spacing-06;
		@include carbon--type-style("productive-heading-03");
		span {
			@include carbon--type-style("body-short-01");
			color: $carbon--gray-80;
			display: block;
		}
	}

	.sc--details-postcode-state-wrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		#customer-add--address-state {
			margin-right: $spacing-07;
		}
		.bx--list-box__wrapper {
			flex: 1 1 auto;
			margin-right: 2rem;
		}
		.bx--form-item {
			flex: 0 1 25%;
		}
	}
	.bx--file-container {
		margin-top: 1rem;
	}
}

.sc--details-country-wrapper {
	.bx--dropdown__wrapper {
		width: 100%;
	}
}

.#{$requestFlowPrefix}-progress-indicator {
	.bx--progress-step--current {
		.bx--progress-label {
			font-weight: bold;
		}
	}
	.bx--progress-step--disabled {
		pointer-events: none;
	}
}

.#{$requestFlowPrefix}-step {
	.bx--label--disabled {
		color: $carbon--gray-80;
	}
}

.bx--form__helper-text--disabled {
	color: $carbon--gray-60;
}

.#{$requestFlowPrefix} {
	&-action-radio-buttons {
		.#{$requestFlowPrefix}-action-radio-button {
			margin-bottom: $spacing-05 !important;
			label {
				align-items: flex-start;
			}
		}
	}
	&-radio-label-text-title {
		display: block;
		@include carbon--type-style(body-short-02, true);
		font-weight: bold;
		margin-bottom: $spacing-02;
	}
}

.lightbox-open {
	.lightbox-backdrop {
		z-index: 9999;
	}
}

.#{$tileRequest}-title,
.#{$tileRequest}-service-type {
	margin-bottom: $spacing-04;
	margin-left: 0;
}
.#{$tileRequest}-title {
	margin-right: 5rem;
}

.#{$tileRequest}-status {
	.bx--tag {
		border-radius: 0;
		margin-bottom: $spacing-04;
		margin-top: 0;
		font-size: 12px;
		margin-left: 0;
	}
}

.#{$tileRequest}-priority {
	position: absolute;
	right: $spacing-05;
	border-radius: 0;
	margin-bottom: $spacing-04;
	margin-top: 0;
	font-size: 12px;
	margin-left: 0;
	top: $spacing-05;
}

.dnd-contractor-context-list {
	margin: $spacing-05;
	.contractor-card {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #efefef;
		padding: 0.75rem;
		border-radius: 3px;
		background: #f9fafa;
		.contractor-details {
			display: flex;
			flex-direction: column;
			> span {
				margin-bottom: $spacing-02;
			}
		}
	}
	.#{$requestFlowPrefix} {
		&-title {
			font-family: "Palanquin Dark", sans-serif;
			font-weight: 700;
			font-size: 1rem;
		}
	}
}

.sc--no-request-notifcation {
	margin-top: $layout-07;
	margin-left: $spacing-06;
	h2 {
		margin-bottom: $spacing-03;
		@include carbon--type-style("expressive-heading-04");
	}
}

.sc--modal-request-info .bx--form-item {
	margin-top: $spacing-06;
}

.sc--request-history-wrapper {
	margin-top: $layout-05;
	.inner-wrapper {
		max-height: 500px;
		overflow-y: scroll;
	}
	.request-history-textarea-container {
		margin-bottom: $spacing-06;
		.bx--tag {
			border-radius: 0;
			margin-bottom: $spacing-04;
			margin-top: 0;
			margin-left: 0;
			padding: $spacing-05;
		}
	}
}

.sc--contractor-quote-amount {
	ul {
		margin-left: $spacing-05;
	}
}

.sc--request-flow-action-radio-button.bx--radio-button-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: start;
	.sc--additional-request-fields {
		margin-left: 10px;
		margin-top: 1rem;
	}
	fieldset.bx--fieldset.sc--awaiting-quote {
		margin-top: $spacing-05;
		margin-bottom: $spacing-05;
	}
}

.sc--request-internal-messaging {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	.bx--form-item {
		width: 100%;
	}
}

.new-note {
	position: absolute;
	top: -5px;
	right: -5px;
	svg {
		fill: $red-60;
		width: 20px;
		height: 20px;
	}
}
