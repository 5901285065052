//Color vars
$aqua: #12cec4;
$aqua-dark: #43b9b0;
$aqua-darker: #3ea29b;
$charcoal: #2d2d2d;
$steel: #efefef;
$orange: #ff7f02;
$orange-dark: #fc5e02;

#12CEC4 h1,
h2,
h3,
h4,
h5,
h6 {
	color: $charcoal;
}

.bx--btn--primary {
	background-color: $aqua;
	&:hover {
		background-color: $aqua-dark;
	}
	&:active {
		background-color: $aqua-darker;
	}
	&:focus {
		border-color: $aqua;
	}
}

.bx--btn--secondary {
	&:focus {
		border-color: $aqua;
	}
}

.bx--btn--ghost {
	color: $aqua;

	&:hover {
		color: $aqua-dark;
	}

	.bx--btn__icon path {
		fill: $aqua;
	}
}

.bx--progress-step:not(.bx--progress-step--disabled) svg,
.bx--btn--ghost:hover .bx--btn__icon path,
.bx--btn--ghost:active .bx--btn__icon path {
	fill: $aqua-dark;
}

.bx--text-input:focus,
.bx--text-input:active {
	outline: 2px solid $aqua;
}

.bx--progress-step:not(.bx--progress-step--disabled) .bx--progress-label:hover,
.bx--progress-step:not(.bx--progress-step--disabled) .bx--progress-label:focus {
	color: $aqua;
	box-shadow: 0 1px $aqua-dark;
}

.bx--radio-button:disabled + .bx--radio-button__label .bx--list__item {
	color: $carbon--gray-30;
}

.bx--progress-step--current .bx--progress-line,
.bx--progress-step--complete .bx--progress-line {
	background-color: $aqua;
}

.bx--progress-label:active {
	box-shadow: none;
}

.bx--text-input {
	background-color: $steel;
	&:disabled {
		color: $carbon--gray-50;
		&:focus {
			outline: none;
		}
	}
}

.bx--loading__stroke {
	stroke: $orange;
}

.bx--tile--clickable:focus,
.bx--text-area:focus,
.bx--text-area:active {
	outline-color: $orange;
}

.bx--content-switcher-btn:focus,
.bx--radio-button:focus + .bx--radio-button__label .bx--radio-button__appearance {
	box-shadow: inset 0 0 0 2px $aqua-dark;
}

span.bx--tag.bx--tag--orange {
	background: $orange;
	color: #ac3f00;
}
