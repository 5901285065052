@include carbon--colors();

.sc--main-wrapper {
  margin-top: $carbon--spacing-09;
  margin-bottom: $carbon--spacing-09;
}

.sc--main-title {
  margin-bottom: $layout-05;
  padding-top: 2rem;
}

a.sc--nav-item.bx--header__menu-item {
  position: relative;
  font-weight: 800;
  color: #fff;
  &.active::after {
    position: absolute;
    bottom: -2px;
    width: 100%;
    height: 3px;
    background: $aqua;
    display: block;
    content: "";
    left: 0;
    top: auto;
    border-bottom: none;
  }
}

a.sc--header-link.bx--header__name {
  background-image: url("../images/logo.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  height: 30px;
  margin-left: 1rem;
  max-width: 190px;
  span {
    display: none;
  }
}

.bx--header__nav {
  display: block;
  width: 100%;
}

h1 {
  @include carbon--type-style("productive-heading-07");
}

.sc--current-page .bx--link {
  color: inherit !important;
}

.sc--notification {
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 1rem;
  max-width: none;
  margin-right: 1rem;
  width: auto;
  right: 0;
}
.bx--list-box__field:focus {
  outline: 2px solid #33cec4;
}

.d-flex {
  display: flex !important;
}
