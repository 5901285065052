//Fonts

@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&family=Palanquin+Dark:wght@400;700&display=swap);

// Font Variables
$body-font: 'Nunito', sans-serif;
$header-font: 'Palanquin Dark', sans-serif;

// Styles
body {
  font-family: $body-font;
  font-weight: 400;
}
h1, h2, h3, h4, h5, h6 {
  font-family: $header-font;
  font-weight: 700!important;
}


.helper-text{
	@include carbon--type-style(helper-text-01, true);
}

.bx--loading-overlay{
	background-color:rgba(255, 255, 255, 0.8);
}

.bx--link{
	&:visited{
		color: $carbon--blue-60;
	}
}

.sc--header-wrapper {
	background-color: $charcoal
}


