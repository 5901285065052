$scPrefix: 'sc';

.sc--composed-modal{
	height: 100vh;
	&--body{
		@include carbon--type-style(body-short-01, true);
	}
}

.#{$scPrefix}--modal-danger{
	.bx--modal-container{
		max-width: 560px;
	}
}