.sc--table-wrapper {
	.sc--search-input{
		.bx--search-input {
			background-color: $carbon--gray-10;
		}
	}
}

.sc--table-row td{
	vertical-align: middle;
}

.bx--data-table th:last-of-type {
	width: 50px;
}

.sc--edit-table-icon{
	color: $cool-gray-70;
	background: none!important;
	box-shadow: none!important;
	// visibility: hidden;
	min-height: 0;
	border: none;
}

.sc--table-row{
	&:hover{
		.sc--edit-table-icon{
			color: $aqua;
			&:hover{
				color: $aqua-darker;
			}
			visibility: visible;
		}
	}
}

.bx--table-header-label{
	font-family: $header-font;
  font-weight: 400;
}
